import { ReactNode } from 'react'
import { useRouter } from 'next/router'

import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import { USER_HOME_PAGE_LINK } from 'src/constants'
import BlankLayout from 'src/@core/layouts/BlankLayout'

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Error404 = () => {
  const router = useRouter()

  const goToHome = () => {
    if (router.asPath.startsWith('/admin/')) {
      router.push('/admin/training_contents')
    } else if (router.asPath.startsWith('/management/')) {
      router.push('/management/users')
    } else {
      router.push(USER_HOME_PAGE_LINK)
    }
  }

  return (
    <Box className='content-center'>
      <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <BoxWrapper>
          <Typography variant='h1'>404</Typography>
          <Typography variant='h5' sx={{ mb: 1, fontSize: '1.5rem !important' }}>
            ページが見つかりませんでした。
          </Typography>
        </BoxWrapper>
        <Button component='a' variant='outlined' sx={{ px: 5.5, marginTop: 4 }} onClick={goToHome}>
          トップページ
        </Button>
      </Box>
    </Box>
  )
}

Error404.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default Error404
